import en from "./locales/en.json";
import kr from "./locales/kr.json";
import {
    getCache,
    setCache,
    removeCache,
    getCookie,
    setCookie,
    removeCookie,
    clearAllStorageData,
} from "./utils/cache";
import {
    CACHE,
    KAINOS_RESPONSE_STATUS_TYPE,
    FLAG_YES,
    FLAG_NO,
} from "./utils/constants";
import { getEnv, jwt, formatDateFromTimestamp } from "./utils";
import endpoints from "./apis/endpoints";
import { ElNotification } from "element-plus";
import { SuccessFilled } from "@element-plus/icons-vue";

export {
    en,
    kr,
    getCache,
    setCache,
    removeCache,
    getEnv,
    CACHE,
    KAINOS_RESPONSE_STATUS_TYPE,
    jwt,
    formatDateFromTimestamp,
    getCookie,
    setCookie,
    removeCookie,
    clearAllStorageData,
    endpoints,
    FLAG_YES,
    FLAG_NO,
};

export const notifyComplete = (msg: string) => {
    ElNotification({
        icon: SuccessFilled,
        message: msg,
        customClass: "complete-message",
        type: "success",
        showClose: false,
    });
};
